












import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'VulnList' })
export default class VulnList extends VueBase {
  @Prop() item: any
  @Prop() getTableData: any
  @Prop() source_type: any

  private toPathName(name: any) {
    this.$router.push({
      name: name,
    })
  }

  async deleteVuln() {
    this.$confirm(
      '该操作将会删除选中漏洞',
      this.$t('views.vulnList.recheckInfo') as string,
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    ).then(async () => {
      const res = await this.services.vuln.vulListDelete({
        source_type: this.source_type,
        ids: String(this.item.id),
      })

      if (res.status !== 201) {
        this.$message({
          type: 'error',
          message: res.msg,
          showClose: true,
        })
      } else {
        this.$message({
          type: 'success',
          message: res.msg,
          showClose: true,
        })
      }
      this.getTableData(true)
    })
  }
}
